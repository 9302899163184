import React from 'react'
import stock from '../images/stock.png'
import ReactTooltip from 'react-tooltip';

const StockPrediction = () => {
  return (
    <div className='flex w-screen items-center justify-center min-h-screen bg-white'>
      <span className='whole'>
        <span className='right1'>
            <img src = {stock} alt='' className='img'/>
        </span>
        <span className='left1 mt-4'>
            <a data-tip="Stock Predictor Github Repository" rel="noreferrer" target="_blank" className='font-extrabold text-3xl text-red-700' href='https://github.com/SrijithVenkat/stock-predictor'>Stock Predictor</a>
            <ReactTooltip/>
            <p> A <span className='font-extrabold text-md hover:text-green-600'>Python</span> Program that utilizes Linear and Polynomial Regression from the past 2 months of a stock to provide a prediction.
            This program produces calls to <a rel="noreferrer" target="_blank" href='https://twelvedata.com/' className='font-extrabold text-md hover:cursor-pointer hover:text-green-600'>TwelveData API</a> to obtain real-time Stock Data to use in computation and testing. The data is then computed
            by the <span className='font-extrabold text-md hover:text-green-600'>Numpy</span> and the built-in Python stats library to handle the mathematics behind the models.
            Unit Testing of both Linear and Polynomial Models resulted in <span className='font-extrabold text-md hover:text-green-600'>~57.1% Accuracy Rate</span> with a <span className='font-extrabold text-md hover:text-green-600'>5%</span> margin of error.
            </p>
        </span>
      </span>
    </div>
  )
}

export default StockPrediction