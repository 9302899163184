import React from 'react'
import '../Portfolio.css'
import steampunk from '../images/steampunked.png'
import ReactTooltip from 'react-tooltip';


const SteamPunked = () => {
  return (
    <div name='Portfolio' className='flex w-screen items-center justify-center min-h-screen bg-white'>
      <span className='whole'>
        <span className='right1'>
            <img src = {steampunk} alt='' className='img'/>
        </span>
        <span className='left1'>
            <a data-tip="Steam Punked Github Repository" rel="noreferrer" target="_blank" className='font-extrabold text-3xl text-purple-700' href='https://github.com/SrijithVenkat/steampunked-game'>Steam Punked</a>
            <ReactTooltip />
            <p>A game where 2 players compete with each other
            to see which player can connect their pipes from one side to the other with the pipes provided. You can rotate, move, install or discard your pipes. Each 
            pipe you place, the slot of which the pipe was taken is replaced. This game
            incorporates <span className='font-extrabold text-md hover:text-purple-600'>XML</span> for the Front End, and <span className='font-extrabold text-md hover:text-purple-600'>MySQL</span> for the backend. Written in <span className='font-extrabold text-md hover:text-purple-600'>Java</span> on <span className='font-extrabold text-md hover:text-purple-600'>Android Studio</span>.</p>
        </span>
      </span>
    </div>
  )
}

export default SteamPunked