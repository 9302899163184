import React, {useEffect, useState} from 'react'
import { motion, useScroll } from 'framer-motion';
import "./ScrollCircle.css";

const ScrollCircle = () => {
    
    const { scrollYProgress } = useScroll();
    const [show, setShow] = useState(true);
    useEffect(() => 
    {
        const checkFinished = () =>
        {
           if (scrollYProgress.current === 1)
           {
            setShow(false)
           }
           else
           {
            setShow(true)
           }
           console.log(scrollYProgress)
        }
        window.addEventListener('scroll', checkFinished);

        return () => window.removeEventListener('scroll', checkFinished);
    }, [scrollYProgress])
    
    return (
        <div id='circ' className='hidden md:flex flex-col bottom-[6%] left-10 fixed'>
            {show && <svg id="progress" width="100" height="100" viewBox="0 0 100 100">
                <circle cx="50" cy="50" r="30" pathLength="1" className="bg" />
                <motion.circle
                    cx="50"
                    cy="50"
                    r="30"
                    pathLength="3"
                    className="indicator"
                    style={{ pathLength: scrollYProgress }}
                />
            </svg>}
        </div>
  )
}

export default ScrollCircle