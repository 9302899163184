import React from 'react'
import macwebsite from '../images/macwebsite.png'
import ReactTooltip from 'react-tooltip';

const OldWebsite = () => {
  return (
    <div className='flex w-screen items-center justify-center min-h-screen bg-white'>
    <span className='whole'>
      <span className='right1'>
            <a data-tip="View Old Website" rel="noreferrer" target="_blank" href="https://cosmic-belekoy-8df923.netlify.app">
                <img id='macadjust' src = {macwebsite} alt='' className='img'/>
            </a>
            <ReactTooltip />
      </span>
      <span className='left1 mt-10'>
          <a data-tip="Old Website Github Repository" rel="noreferrer" target="_blank" className='font-extrabold text-3xl text-slate-700' href='https://github.com/SrijithVenkat/my-portfolio'>React Website Portfolio</a>
          <ReactTooltip />
          <p> This <span className='font-extrabold text-md hover:text-yellow-600'>React</span> website was one of my first projects and used to be my old portfolio website. This website has many similarities
          with the one you are viewing currently. It was built in <span className='font-extrabold text-md hover:text-yellow-600'>React</span> using <span className='font-extrabold text-md hover:text-yellow-600'>HTML, CSS and Javascript</span>.
          This site was pertinent for my <span className='font-extrabold text-md hover:text-yellow-600'>React</span> experience and future endeavors, looking back I now notice many things I would not have before. I know I can improve further
          in the projects and websites I work on in the future.
          </p>
      </span>
    </span>
  </div>
  )
}

export default OldWebsite