import React from 'react'
import { FaGithub, FaLinkedin } from 'react-icons/fa'
import { HiOutlineMail } from "react-icons/hi";
import { GrDocumentDownload } from 'react-icons/gr';
import './Contact.css'
import './Portfolio.css'
import resume from '../resume.pdf'
import toast, { Toaster } from "react-hot-toast";
import ReactTooltip from 'react-tooltip';

const Contact = () => {

  const notify = () => toast.success('Copied Email');

  return (
    <div name="Contact" className='flex w-screen items-center justify-center min-h-screen bg-white'>
      <span className='whole'>
        <span className='right'>
            <form
              action="https://getform.io/f/9e9ccce6-cb0d-4cf0-94b5-670a64d282a6"
              method="POST"
              className=" flex flex-col w-full justify-center items-center"
            >
              <h3 className='text-2xl font-semibold my-3'>Contact</h3>
              <input
                type="text"
                name="name"
                placeholder="Enter your name"
                className="name-box"
              />
              <input 
                type="text"
                name="email"
                placeholder="Enter your email"
                className="my-4 name-box"
              />
              <textarea
                name="message"
                placeholder="Enter your message"
                rows="10"
                className="p-2 name-box"
              ></textarea>

              <button className="connect-button">
                Let's connect
              </button>

              <div className='contacts-container'>
                <a data-tip="LinkedIn" rel="noreferrer" target="_blank"  href = "https://www.linkedin.com/in/srijith-venkateshwaran" className="m-5 items-center"><FaLinkedin className='fill-black'   size={45} /></a>
                <ReactTooltip/>
                <a data-tip="Github" rel="noreferrer" target="_blank"  href = "https://github.com/SrijithVenkat" className="m-5 items-center"><FaGithub className='fill-white bg-black rounded-md' size={40} /></a>
                <ReactTooltip/>
                <a data-tip="Copy Email" href='#0' onClick={() => { navigator.clipboard.writeText('srijithvenkateshwaran@gmail.com'); notify();}} className="m-5 items-center" ><HiOutlineMail className='fill-white bg-black rounded-md' size={40}/></a>
                <ReactTooltip/>
                <Toaster/>
                <a data-tip="Download Resume" href={resume} className="m-5 items-center" download={true}> <GrDocumentDownload className='fill-black' size= {40} /></a>
                <ReactTooltip/>
              </div>
            </form>
        </span>
      </span>
    </div>
  )
}

export default Contact