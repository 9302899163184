import NavBar from './components/NavBar';
import Home from './components/Home';
import About from './components/About';
import ScrollCircle from './components/ScrollCircle';
import Contact from './components/Contact';
// import SocialLinks from './components/SocialLinks';
import OldWebsite from './components/Portfolio/OldWebsite';
import StockPrediction from './components/Portfolio/StockPrediction';
import SteamPunked from './components/Portfolio/SteamPunked';
function App() {
  return (
    <div>
      <NavBar/>
      <Home />
      <About/>
      <SteamPunked/>
      <StockPrediction/>
      <OldWebsite/>
      <Contact/>
      <ScrollCircle/>
      {/* <SocialLinks/> */}
    </div>
  );
}

export default App;
