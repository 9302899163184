import React from 'react'
import sri from './images/Srijith.jpeg'
import './Home.css'
import { motion} from 'framer-motion'
import shadow from './images/shadowcircle.png'
const Home = () => {

    const line1 = "Hi, I'm Srijith"
    const line2 = "welcome to my portfolio."

    const sent = 
    {
        hidden: {opacity: 1},
        visible: { opacity: 1, transition: {delay: 1, staggerChildren: .1} }
    }
    const letter = 
    {
        hidden: {opacity: 0, y:50},
        visible: {opacity: 1, y: 0}
    }
    return (
        <div name = "Home" className='flex min-h-screen w-full bg-white'>
            <div className='img-contain'>
                <motion.div 
                        initial = {{y: "150%", opacity:0, onBlur: 1}}
                        animate={{ y: "15%", opacity: 1, transition:  {ease: [.6,.01,-.05,.95], duration: 2, onBlur: 0}}}
                        className='justify-center items-center'>
                        <div className='sm:w-10/12'>
                                <motion.h3
                                className='my-5 mx-14' 
                                variants= {sent} 
                                initial = "hidden" 
                                animate= "visible">
                                {line1.split("").map((char,ind) => 
                                    {
                                    return (
                                        <motion.span className='text-4xl font-semibold' key= {char+"-"+ind} variants = {letter}>
                                            {char}
                                        </motion.span>
                                    )
                                    })
                                }   
                                <br />
                                <div className=' align-middle'>
                                    {line2.split("").map((char,ind) => 
                                        {
                                        return (
                                            <motion.span className='text-lg font-medium' key= {char+"-"+ind} variants = {letter}>
                                                {char}
                                            </motion.span>
                                        )
                                        })
                                    } 
                                </div>
                                </motion.h3>
                                <div>
                                    <img className='pfp shadow-sm' src={sri} alt="" />
                                    <motion.img 
                                    initial = {{opacity: 0}}
                                    animate = {{opacity: 1, transition: {duration: 10}}}
                                    className='absolute h-auto' src = {shadow} alt = ""/>
                                </div>
                        </div>
                </motion.div>
            </div>
        </div>
        
    )
}

export default Home;
