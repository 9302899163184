import React, {useState} from 'react';
import {Link} from 'react-scroll';
import {FaBars, FaTimes} from 'react-icons/fa';
import { motion } from 'framer-motion';
import './NavBar.css'
const NavBar = () => {

    const [nav, setNav] = useState(false)
    // const menuItem = {
    //     closed: {
    //       opacity: 0
    //     },
    //     open: { opacity: 1 }
    //   };

    //   const sideVariants = {
    //     closed: {
    //       transition: {
    //         staggerChildren: 0.2,
    //         staggerDirection: -1
    //       }
    //     },
    //     open: {
    //       transition: {
    //         staggerChildren: 0.2,
    //         staggerDirection: 1
    //       }
    //     }
    //   };

    const links = [
        {
            id: 1,
            link: 'Home'
        },
        {
            id: 2,
            link: 'About'
        },
        {
            id: 3,
            link: 'Portfolio'
        },
        {
            id: 4,
            link: 'Contact'
        },

    ]


    // const [visible, setVisible] = useState(true);

    // useEffect(() => {
    //     const handleScroll = () => {
    //             const currentScrollPos = window.pageYOffset;
    //             console.log(currentScrollPos)
    //             if (currentScrollPos > 100)
    //             {
    //                 setVisible(false)
    //             }
    //             else
    //             {
    //                 setVisible(true)
    //             }
    //         };
    //   window.addEventListener('scroll', handleScroll);
  
    //   return () => window.removeEventListener('scroll', handleScroll);
  
    // }, [visible]);

    return (
        <motion.div 
            className='flex justify-around items-center w-full h-20 text-black fixed font-bold bg-transparent z-10'>
                <motion.div   
                    initial={{ x: "-100%" , opacity: 0}}
                    animate={{ x: "calc(5vh + 30%)", opacity: 1}}
                    transition = {{duration: 3, ease: 'anticipate', delay: 3}}
                    whileHover = {{scale: 1.05, cursor: 'pointer', transition: {duration: 1.5}}}
                    className='name-text'><Link to={'Home'} smooth duration={500}>SV</Link>
                </motion.div>

                <div className='hidden md:flex'>
                    <motion.ul 
                        initial={{ x: "100%", opacity: 0}}
                        animate={{ x: "calc(-20%)", opacity: 1}}
                        transition = {{duration: 3, ease: 'anticipate', delay: 3}}
                        className='flex flex-row'>
                            {links.map(({id,link}) =>
                                <motion.li
                                    whileHover = {{underlinePosition: 'screenTop'}}
                                    key={id} className='pagename-text'>
                                    <Link to={link} smooth duration={500}>{link}</Link>
                                </motion.li>
                            )}
                    </motion.ul>
                </div>

                <div className='flex md:hidden'>
                    <motion.div 
                        initial={{ x: "100%", opacity: 0}}
                        animate={{ x: "calc(-20%)", opacity: 1}}
                        transition = {{duration: 3, ease: 'anticipate', delay: 5}}
                        onClick={() =>setNav(!nav)} 
                        className='cursor-pointer pr-4 z-10 text-gray-500 flex md:hidden'>
                            {nav ? <FaTimes size = {20}/> : <FaBars size = {20}/> }
                    </motion.div>
                </div>

                    {nav && (
                        <ul className='flex flex-col justify-center items-center absolute top-0 left-0 w-full h-screen bg-white
                        text-gray-500'>
                            {links.map(({id, link}) =>
                            <li key = {id} className='px-4 py-4 cursor-pointer font-thin text-black hover:scale-105 duration-200 text-3xl'>
                                <Link to={link} smooth duration={500}>{link}</Link>
                            </li>
                            )}
                        </ul>
                    )}
                    
        </motion.div>
    )
}

export default NavBar