import React, { useState} from 'react';
import './ImageGallery.css';
import { motion } from 'framer-motion';

import diwali from './images/diwali.png'
import hiking from './images/hiking.png'
import mdrd from './images/mdrdpic.png'
import volunteer from './images/volunteer.png'
import formal from './images/formal.png'

const { forwardRef, useImperativeHandle } = React;

const GalleryImages = [
  {
    image: mdrd,
    display: 'At a restaurant called MDRD, one of my\nfavorite hobbies is to try new foods! However,\nI do not recommend this restaurant.'
  },
  {
    image: diwali,
    display: 'Celebrating Diwali with some small\nfireworks outside, after the CIUS Diwali\nevent held on MSU Campus!'
  },
  {
    image: volunteer,
    display: 'Volunteering with the other Eboard\nmembers to lead one of the many groups\nof students who volunteered to help grow\ncrops for a local orphanage.'
  },
  {
    image: hiking,
    display : 'I highly recommend this trail near Grand\nTraverse as it is one of my favorite trails.\nI went with a few friends and got moomers\nafter!'
  },
  {
    image: formal,
    display : 'Pictured is my girlfriend and I at one of the\nbiggest CIUS events of the year, formal as\nwe call it is a chance for everyone to gather\nin Indian clothing and eat Indian food together.'
  }
];

const ImageGallery = forwardRef((slides, ref) => {

    const [curr, setCurr] = useState(0);
    const length = 5;

    useImperativeHandle(ref, () => ({
  
        nextSlide() {
            console.log(length)
            setCurr(curr === length - 1 ? 0 : curr + 1);
        },
        
        prevSlide() {
            setCurr(curr === 0 ? length - 1 : curr - 1);
        },
    }));
  
    return (
        <section className='slider'>
        {GalleryImages.map((slide, index) => {
          return (
            <div
              className={index === curr ? 'slide active' : 'slide'}
              key={index}
            >
              {index === curr && (
                  <motion.div 
                    initial = {{x: '-50%'}}
                    animate = {{x : '0%'}}
                    className='flex flex-col'>
                      <img src={slide.image} alt='' className='image' />
                      <p className='font-semibold relative mt-5 mb-10 whitespace-pre-wrap'>{slide.display}</p>
                  </motion.div>
              )}
            </div>
          );
        })}
      </section>
    );
  });

export default ImageGallery;